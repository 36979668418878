div.activityGrid div.activityGridImgBackground{
    position: relative;
    min-height: 55vh;
    width: 100%;
    background: url(../../images/landing04.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: contain;
}

div.activityGrid h1{
    letter-spacing: 1px;
    font-size: 50px;
    padding: 10px;
    margin-top: 90px;
    text-align: center;
}