footer {
    background-color: #19181d;
    color: #ffffff;
    font-size: 16px;
  }
  footer * {
    font-family: "Poppins", sans-serif;
    box-sizing: border-box;
    border: none;
    outline: none;
  }
  footer .row {
    padding: 2em 1em;
  }
  footer .row.primary {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    align-items: stretch;
  }
  footer .column {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 2em;
    min-height: 15em;
  }
  footer h3 {
    width: 100%;
    text-align: left;
    color: rgba(255,255,255,.85);
    font-size: 1.6em;
    white-space: nowrap;
  }
  footer ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
  footer ul li:not(:first-child) {
    margin-top: 0.8em;
  }
  footer ul li a {
    color: #e2e2e2;
    text-decoration: none;
  }
  footer ul li a:hover {
    color: #fff;
  }
  footer .about p {
    text-align: justify;
    line-height: 2;
    margin: 0;
  }
  footer input,
  footer button {
    font-size: 1em;
    padding: 1em;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 5px;
  }
  footer button {
    background-color: #00754B;
    color: #ffffff;
    transition: 0.3s;
    cursor: pointer;
  }

  footer button:hover{
    background-color: #008856;
  }

  footer div.social {
    display: flex;
    justify-content: left;
    font-size: 2.4em;
    flex-direction: row;
    margin-top: 0.5em;
    cursor: pointer;
  }
  footer .social i{color: #e2e2e2; padding: 0 10px;}
  footer .social i:hover {
    color: #fff;
    /* background-color: #25262e; */
  }
  footer .secondary {
    display: flex;
    justify-content: space-around;
    text-align: center;
  }
  footer .secondary i {
    font-size: 1.8em;
    color: rgba(255,255,255,.85);
  }
  footer .secondary div {
    padding: 1em 0;
    width: 100%;
  }
  footer .secondary div:hover {
    background-color: #25262e;
  }
  footer .copyright {
    padding: 0.3em 1em;
    border-top: 2px solid #25262e;
    /* background-color: #25262e; */
  }
  footer .copyright p {
    font-size: 0.9em;
    text-align: center;
  }
  @media screen and (max-width: 850px) {
    footer  .row.primary {
      grid-template-columns: 1fr;
    }
    footer .secondary {
      flex-direction: column;
    }
  }