div.firstActivity1{
    background: #ececec;
}
div.firstActivity{
    display: flex;
    flex-direction: row;
    background: #f6f6f6;
}

div.firstActivity div.firstActContent{
    min-height: 100vh;
    margin: 200px auto 0 auto;
}

div.moreTextView{
    margin: 50px 0;
    width: 60vw;
}

div.moreTextView ul{
    list-style-type: none;
    line-height: 26px;
}

div.moreTextView ul li{
    font-size: 19px;
    padding: 9px 0;
}

.innerLi{
    text-indent: 50px;
    font-weight: 600;
}

div.activitiesImgDivB{
    margin: 100px 0 70px 0;
    width: 60vw;
}

div.activitiesImgDivB img{
    max-width: 60vw;
}

i.iconInFront{
    padding: 5px 10px 0 0;
    font-size: 25px;
    color: #00754B;
    
}

@media screen and (max-width: 1265px) {
    div.activitiesImgDivB img{
        max-width: 80vw;
    }

    div.moreTextView{
        /* margin: 50px 0; */
        width: 80vw;
    }
}