div.privacyPage div.privacyImgBackground{
    position: relative;
    min-height: 55vh;
    width: 100%;
    background: url(../../images/landing04.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: contain;
}

section.privacyText{
    /* text-align: center; */
    width: 60vw;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
    font-size: 19px;
    line-height: 1.7em;
    margin: auto;
    margin-top: 100px;
    text-align: justify;
}

section.privacyText p{
    padding-bottom: 1em;
}

.privacyTitle{
    font-size: 42px;
    font-weight: 500;
}

@media screen and (max-width: 1555px) {
    .privacyTitle{
        font-size: 32px;
    }
}


@media screen and (max-width: 690px) {
    section.privacyText{
        width: 80vw;
    }
}