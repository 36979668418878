nav{
    position: fixed;
    z-index: 999;
    width: 100%;
    /* background: #000; */
    transition: 0.6s;
    border-bottom: 0.5px solid snow;
  }
  nav .wrapper{
    position: relative;
    max-width: 1300px;
    padding: 25px 30px;
    height: 70px;
    /* line-height: 70px; */
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  nav.sticky{
    background: #fff;
    border-bottom: 0.5px solid #474747;
  }

  nav.sticky .wrapper{
    padding: 10px 30px;
  }

  nav.sticky .wrapper .logo a{
    color: #242526;
  }

  nav.sticky .wrapper .nav-links li a{
    color: #242526;
  }

  nav.sticky .wrapper .nav-links li .hrefPhone{
    color: #fff;
  }

  nav.sticky .wrapper .nav-links li a.active{
    border-bottom: 1px solid #242526;
    padding: 13px 15px;
  }

  nav.sticky .wrapper .nav-links li a:hover{
    border-bottom: 1px solid #242526;
  }

  nav.stickyReady{
    background: #fff;
    border-bottom: 0.5px solid #474747;
  }

  nav.stickyReady .wrapper{
    padding: 10px 30px;
  }

  nav.stickyReady .wrapper .logo a{
    color: #242526;
  }

  nav.stickyReady .wrapper .nav-links li a{
    color: #242526;
  }

  nav.stickyReady .wrapper .nav-links li a:hover{
    border-bottom: 0.5px solid #242526;
  }

  nav.stickyReady .wrapper .nav-links li .hrefPhone{
    color: #fff;
  }

  nav .wrapper .nav-links li .hrefPhone{
    padding: 7px;
    font-size: 1.2rem;
    color: #fff;
    text-decoration: none;
    border-radius: .3rem;
    background-size: 200%;
    background-image: linear-gradient(to left, rgb(0, 133, 84), rgb(0, 85, 54) ,  rgb(0, 133, 84));
    transition: 0.6s;
    border: none;
    cursor: pointer;
    margin-left: 5px;
  }

  nav .wrapper .nav-links li button:hover {
    background-position: right;
    /* background-image: linear-gradient(to right, rgb(0, 85, 54), rgb(0, 133, 84), rgb(0, 85, 54)); */
}

  .wrapper .logo{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15rem;
    max-width: 100%;
    height: 100%;
  }

  .wrapper .logo a{
    display: block;
    width: 100%;
    /* padding: 20px 0px 20px 0; */
  }

  .wrapper .logo img{
    width: 140px;
    height: auto;
    vertical-align: middle;
    float: none;
    /* height: 30px; */
  } 

  .wrapper .nav-links{
    display: inline-flex;
    line-height: 70px;
  }
  .nav-links li{
    list-style: none;
  }
  .nav-links li a{
    color: #f2f2f2;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    padding: 9px 15px;
    /* border-radius: 5px; */
    transition: all 0.3s ease;
  }

  .nav-links li a.active{
    border-bottom: 1px solid #fff;
    /* transform: translateY(-2.1rem); */
    padding: 13px 15px;
  }

  .nav-links li a:hover{
    /* background: #3A3B3C; */
    border-bottom: 1px solid #fff;
  }

  .nav-links li .drop-menu a:hover, .nav-links li .mega-links a:hover{
    border-bottom: 1px solid #242526;
  }

  .nav-links .mobile-item{
    display: none;
  }
  .nav-links .drop-menu{
    position: absolute;
    background: #fff;
    width: 220px;
    line-height: 45px;
    top: 85px;
    /* margin-left: -20px; */
    padding: 10px 30px;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 6px 10px rgba(0,0,0,0.15);
  }
  .nav-links li:hover .drop-menu,
  .nav-links li:hover .mega-box{
    transition: all 0.3s ease;
    top: 70px;
    opacity: 1;
    visibility: visible;
  }
  .nav-links .drop-menu li a{
    width: 100%;
    display: block;
    margin: 0;
    padding: 5px 0 5px 0;
    color: #242526;
    font-weight: 400;
    border-radius: 0px;
  }
  .mega-box{
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0 30px;
    top: 85px;
    opacity: 0;
    visibility: hidden;
  }

  .mega-box .content{
    background: #fff;
    padding: 25px 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    box-shadow: 0 6px 10px rgba(0,0,0,0.15);
  }
  .mega-box .content .row{
    width: calc(25% - 30px);
    line-height: 45px;
    /* padding-top: 20px; */
  }
  .content .row img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .content .row header{
    color: #000;
    font-size: 20px;
    font-weight: 500;
  }
  .content .row .mega-links{
    margin-left: -40px;
    border-left: 1px solid #242526;
  }
  .row .mega-links li{
    padding: 0 20px;
  }
  .row .mega-links li a{
    padding: 0px;
    padding: 0 20px;
    color: #242526;
    font-size: 17px;
    display: block;
  }
  /* .row .mega-links li a:hover{
    color: #f2f2f2;
  } */
  .wrapper .btn{
    color: #000;
    font-size: 20px;
    cursor: pointer;
    display: none;
  }
  .wrapper .btn.close-btn{
    /* color: #fff; */
    position: absolute;
    right: 30px;
    top: 10px;
  }

  /* .logoMenuImg{
    width: 300px;
  } */
  @media screen and (max-width: 1265px) {
    nav{
      background: #fff;
      /* transition: 0.5s; */
    }

    .wrapper .logo a{
      color: #242526;
    }

  nav.sticky .wrapper{
    padding: 10px 30px;
  }
    .wrapper .btn{
      display: block;
    }
    .wrapper .nav-links{
      position: fixed;
      height: 100vh;
      width: 100%;
      max-width: 350px;
      top: 0;
      left: -100%;
      background: #fff;
      display: block;
      padding: 50px 10px;
      line-height: 50px;
      overflow-y: auto;
      box-shadow: 0px 15px 15px rgba(0,0,0,0.18);
      transition: all 0.3s ease;
    }
    /* custom scroll bar */
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background: #242526;
    }
    ::-webkit-scrollbar-thumb {
      background: #3A3B3C;
    }
    #menu-btn:checked ~ .nav-links{
      left: 0%;
    }
    #menu-btn:checked ~ .btn.menu-btn{
      display: none;
    }
    #close-btn:checked ~ .btn.menu-btn{
      display: block;
    }
    .nav-links li{
      margin: 15px 10px;
    }
    .nav-links li a{
      color: #242526;
      padding: 0 20px;
      display: block;
      font-size: 20px;
    }

    .nav-links li a:hover{
      /* background: #3A3B3C; */
      border: none;
    }

    .nav-links li a.active{
      border-bottom: 1px solid #242526;
    }

    .nav-links li a.active:hover{
      border-bottom: 1px solid #242526;
    }

    nav.sticky .wrapper .nav-links li a:not(.active):hover{
      border: none;
    }

    .nav-links .drop-menu{
      position: static;
      opacity: 1;
      top: 65px;
      visibility: visible;
      padding: 0 20px 0 10px;
      /* margin: 5px 0 5px 0; */
      width: 100%;
      max-height: 0px;
      overflow: hidden;
      box-shadow: none;
      transition: all 0.3s ease;
    }
    #showDrop:checked ~ .drop-menu,
    #showDropTwo:checked ~ .drop-menu,
    #showMega:checked ~ .mega-box{
      max-height: 100%;
    }
    .nav-links .desktop-item{
      display: none;
    }
    .nav-links .mobile-item{
      display: block;
      /* color: #f2f2f2; */
      color: #242526;
      font-size: 20px;
      font-weight: 500;
      padding-left: 20px;
      cursor: pointer;
      border-radius: 5px;
      transition: all 0.3s ease;
    }
    /* .nav-links .mobile-item:hover{ */
      /* background: #3A3B3C; */
      /* border-bottom: #fff; */
    /* } */
    .drop-menu li{
      margin: 0;
    }
    .drop-menu li a{
      border-radius: 5px;
      font-size: 18px;
    }
    .mega-box{
      position: static;
      top: 65px;
      opacity: 1;
      visibility: visible;
      padding: 0 20px;
      max-height: 0px;
      overflow: hidden;
      transition: all 0.3s ease;
    }
    .mega-box .content{
      box-shadow: none;
      flex-direction: column;
      padding: 20px 20px 0 20px;
    }
    .mega-box .content .row{
      width: 100%;
      margin-bottom: 15px;
      border-top: 1px solid rgba(230, 230, 230, 0.08);
    }
    .mega-box .content .row:nth-child(1),
    .mega-box .content .row:nth-child(2){
      border-top: 0px;
    }
    .content .row .mega-links{
      border-left: 0px;
      /* padding-left: 15px; */
      visibility: visible;
      padding: 0 20px 0 0px;
      /* margin: 5px 0 5px 0; */
      width: 100%;
      /* color: #3A3B3C; */
      overflow: hidden;
      box-shadow: none;
      transition: all 0.3s ease;
    }
    .row .mega-links li{
      margin: 0;
    }
    .content .row header{
      font-size: 19px;
    }

    nav .wrapper .nav-links li .hrefPhone{
      margin-left: 0;
    }
  }
  nav input{
    display: none;
  }
  .body-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    padding: 0 30px;
  }
  .body-text div{
    font-size: 45px;
    font-weight: 600;
  }