div.PricesPage div.pricesImgBackground{
    position: relative;
    min-height: 55vh;
    width: 100%;
    background: url(../../images/landing04.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: contain;
}

section.pricesText{
    /* text-align: center; */
    width: 60vw;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
    font-size: 19px;
    line-height: 1.7em;
    margin: auto;
    margin-top: 100px;
    text-align: justify;
}

section.pricesTextLaw{
    width: 60vw;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 20vh;
    flex-direction: column;
    font-size: 19px;
    line-height: 1.7em;
    margin: 100px auto 100px auto;
    /* margin-top: 100px; */
    text-align: justify;
}

section.pricesText ul {
    list-style: none;
  }

  section.pricesText ul li{
    line-height: 1.5em;
    font-weight: 400;
    padding: 10px;
  }

  /* section.pricesText ul li i{
    padding: 5px 0 0 0;
    font-size: 25px;
    color: #00754B;
    
} */

section.pricesText p{
    padding-bottom: 1em;
    font-size: 21px;
}

.MainTitlesPrices{
    font-size: 42px;
    font-weight: 500;
}

section.pricesText p strong{
    color: #231f20;
}

.MainTitlesPrices span.priceSpanVisible{
    padding-bottom: 5px;
    border-bottom: 1px solid #242526;
    cursor: pointer;
    transition: 0.3s;
}

.MainTitlesPrices span.priceSpanVisible:hover{
    padding-bottom: 0px;
}

@media screen and (max-width: 1555px) {
    .MainTitlesPrices{
        font-size: 32px;
    }
}

@media screen and (max-width: 1200px) {
    .MainTitlesPrices span.priceSpanVisible{
        display: block;
        border-bottom: 1.5px solid #242526;
        line-height: 1.2em;
    }

    .MainTitlesPrices span.pricesMediationSpan{
        margin-top: 20px;
    }
    .MainTitlesPrices span.spaceBetweenPrices{
        display: none; 
    }

    section.pricesText p{
        font-size: 19px;
    }
}

@media screen and (max-width: 690px) {
    section.pricesText{
        width: 80vw;
    }

    section.pricesTextLaw{
        width: 80vw;
    }
}