@property --angle {
    syntax: '<angle>';
    initial-value: 90deg;
    inherits: true;
  }
  
  @property --gradX {
    syntax: '<percentage>';
    initial-value: 50%;
    inherits: true;
  }
  
  @property --gradY {
    syntax: '<percentage>';
    initial-value: 0%;
    inherits: true;
  }

  :root {
	--d: 2500ms;
	--angle: 90deg;
	--gradX: 100%;
	--gradY: 50%;
	--c1: rgba(168, 239, 255, 1);
	--c2: rgba(168, 239, 255, 0.1);
}

.section-main{
    position: relative;
    min-height: 100vh;
    width: 100%;
    background: url(../../images/landing12.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    object-fit: contain;
    overflow-x: hidden;
}

.section-main .landingText{
    padding: 20px;
    margin-left: 80px;
    height: 50vh;
    width: 100vh;
    border-left: 0.5px solid snow;
    border-top: 0.5px solid snow;
    overflow-x: hidden;
}

.section-main .landingText h1{
    color: #f2f2f2;
    text-transform: uppercase;
    letter-spacing: 0;
    line-height: 1em;
    font-weight: 700;
    font-size: 60px;
    text-shadow: none;
}

.section-main .landingText h2{
    color: #f2f2f2;
    text-transform: uppercase;
    letter-spacing: 6px;
    line-height: 0;
    font-weight: 300;
    font-size: 30px;
    text-shadow: none;
}

.section-main .landingText button.CTF1{
    margin-top: 40px;
    padding: 20px 30px;
    /* border-radius: 15px; */
    /* border: 2px solid snow; */
    cursor: pointer;
    color: #f2f2f2;
    background: none;
    font-size: 20px;
    transition: 0.3s ease-in;
    border: 0.35rem solid;
    border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 30;
	animation: borderRotate var(--d) linear infinite forwards;
}

.section-main .landingText button.CTF1:hover{
    border-image-source: linear-gradient(to left, rgba(168, 239, 255, 1), rgba(168, 239, 255, 0.1));
}


.section-two{
    min-height: 110vh;
    padding: 20px 30px;
    display: flex;
    flex-direction: row;
}

.section-two h2{
    font-size: 50px;
    font-weight: 500;
    margin: 30px 0;
}

.section-two .describingPic{
    height: 100vh;
    /* float: right; */
    border-radius: 10px;
  }

  .section-two .describingPicTop{
    display: none;
    border-radius: 10px;
  }

  /* .section-two div.sticyTextDiv{

    position: relative;
    height: 110vh;
  } */

  .section-two ul.workDesc {
    position: sticky;
    top: 0;
    /* padding-left: 45vw; */
    padding-right: 200px;
    /* list-style-type: "\2715"; */
    list-style: none;
    text-align: left;
  }

  /* .section-two ul.workDesc::marker{
    color: #b8863c;
    content: '+';
    font-size: 22px;
    /* font-family: ETmodules; */
  /*} */

  .section-two ul.workDesc li{
    font-size: 22px;
    line-height: 1.5em;
    font-weight: 400;
    padding: 20px;
    /* text-align: justify; */
  }

    .section-two div.background{
    min-height: 110vh;
    width: 40%;
    position: absolute;
    left: 0;
    z-index: -3;
    background-color: #f3f3f3; 
  } 

  section.midImage{
    position: relative;
    min-height: 70vh;
    width: 100%;
    background: url(../../images/aboutFirm3.jpg) no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    object-fit: contain;
    color: #fff;
  }
  
  section.midImage ol{
    margin: 70px 0 0 100px;
    line-height: 40px;
    font-size: 22px;
  }

a.scroll-btn {
    position: absolute;
    height: 3.5em;
    width: 2em;
    border: 2px solid #f2f2f2;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 2em;
    border-radius: 3em;
    cursor: pointer;
  }
  a.scroll-btn:before {
    position: absolute;
    content: "";
    margin: auto;
    left: 0;
    right: 0;
    top: 0.7em;
    height: 0.7em;
    width: 0.7em;
    background-color: #ffffff;
    border-radius: 50%;
    animation: move-down 2s infinite;
  }

  .section-three{
    min-height: 100vh;
    /* padding: 20px 100px; */
    }

  .section-three h2{
    font-size: 50px;
    text-align: center;
    font-weight: 500;
  }

  .titleTagH2{

    position: relative;
  }

  .titleTagH2 span{
    background: #fff;
  }

  .titleTagH2::before{
    content: '';
    position: absolute;
    top: 50%;
    left: 30%;
    width: 100px;
    background: #242526;
    height: 1px;
    z-index: -99;
    }

    .titleTagH2::after{
      content: '';
      position: absolute;
      top: 50%;
      right: 30%;
      width: 100px;
      background: #242526;
      height: 1px;
      z-index: -99;
      }

  .section-three .gridClauses h2{
    font-weight: 400;
  }


  /* a.scroll-btn:after {
    position: absolute;
    content: "";
    width: 12em;
    display: block;
    width: 12em;
    text-align: center;
    left: -4.2em;
    bottom: -2.5em;
    color: #ffffff;
    letter-spacing: 3px;
    font-weight: 600;
  } */


  .container1{
	margin: 30px;
}

.row{
	width: 100%;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}

.image{
	background: #000;
	position: relative;
	flex: 1;
	max-width: 460px;
	height: 300px;
	margin: 10px;
	overflow: hidden;
  cursor: pointer;
}

.stretched{
  background: #000;
	position: relative;
	flex: auto;
	width: 100%;
	height: 300px;
	margin: 10px;
	overflow: hidden;
  cursor: pointer;
}

.image img{
  width: 500px;
	opacity: 50%;
	position: relative;
	vertical-align: middle;
	transition: 0.6s;
	transition-property: opacity;

}

.image:hover img, .stretched:hover img{
    /* transform:scaleX(1.2); */
	opacity: 70%;
}

.image .details, .stretched .details{
	z-index: 1;
	position: absolute;
	top: 55%;
	right: 0;
	color: #fff;
	width: 100%;
	height: 100%;
}

.image .details h2, .stretched .details h2{
	text-align: left;
	font-size: 25px;
	font-weight: 600;
	margin-top: 70px;
    margin-left: 30px;
	transition: 0.4s;
	transition-property: transform;
}

.image .details h2 span, .stretched .details h2 span{
	font-weight: 900;
}

.image:hover .details h2, .stretched:hover .details h2{
	transform: translateY(-30px);
}

/* .image .details p{
	margin: 30px 30px 0 30px;
	font-size: 18px;
	font-weight: 600;
	text-align: center;
	opacity: 0;
	transition: 0.6s;
	transition-property: opacity, transform;
}

.image:hover .details p{
	opacity: 1;
	transform: translateY(-40px);
} */

/* .more{
	position: absolute;
	background: rgba(255, 255, 255, 0.8);
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
	bottom: -60px;
	transition: 0.6s;
	transition-property: bottom;
}

.image:hover .more{
	bottom: 0;
} */

/* .more .read-more{
	color: #000;
	text-decoration: none;
	font-size: 20px;
	font-weight: 500;
	text-transform: uppercase;
} */

/* .more .read-more span{
	font-weight: 900;
} */


  /*     Animations       */

@keyframes borderRotate {
	100% {
		--angle: 420deg;
	}
}

@keyframes borderRadial {
	20% {
		--gradX: 100%;
		--gradY: 50%;
	}
	40% {
		--gradX: 100%;
		--gradY: 100%;
	}
	60% {
		--gradX: 50%;
		--gradY: 100%;
	}
	80% {
		--gradX: 0%;
		--gradY: 50%;
	}
	100% {
		--gradX: 50%;
		--gradY: 0%;
	}
}

@keyframes move-down {
    80% {
      opacity: 0.5;
    }
    100% {
      transform: translateY(1.3em);
      opacity: 0;
    }
  }

    /*     Responsive       */



@media (max-width: 630px) {
    .section-main h1{
        font-size: 50px;
        line-height: 60px;
    }

    .section-main .landingText h1 strong{
      display: block;
      line-height: 0.95em;
    }

    /* .section-main .landingText h1{
      line-height: 10px;
    } */
}

@media screen and (max-width: 1010px) {
  .section-main .landingText{
    padding: 20px;
    margin-left: 15px;
    height: 50vh;
    width: 100vh;
    border-left: 0.5px solid snow;
    border-top: 0.5px solid snow;
}

.section-main .landingText h1{
    color: #f2f2f2;
    text-transform: uppercase;
    letter-spacing: 0;
    line-height: 1em;
    font-weight: 700;
    font-size: 55px;
    text-shadow: none;
}

}

@media screen and (max-width: 500px) {
    a.scroll-btn {
      font-size: 12px;
    }
  }

  @media (max-width: 1080px){
	.image{
		flex: 100%;
		max-width: 480px;
	}

  .section-two ul.workDesc{
    padding-right: 20px;
  }

}

@media (max-width: 1370px){
  .section-two{
    flex-direction: column;
  }
  .section-two .describingPic{
    /* max-width: 50vw;
    height: auto;
    max-height: 80vh;
    float: none; */
    display: none;
  }
  .section-two .describingPicTop{
    display: block;
    max-width: 100vw;
    height: auto;
    /* max-height: 80vh; */
    float: none;
  }
}

@media (max-width: 400px){
	.image .details p{
		font-size: 16px;
	}

	.more .read-more, .more .icon-links a i{
		font-size: 18px;
	}
}






.ps-timeline-sec {
    position: relative;
    background: #fff;
  }
  .ps-timeline-sec .container {
    position: relative;
    margin: 0 100px;
    min-height: 30vh;
  }
  @media screen and (max-width: 767px) {
    .ps-timeline-sec .container ol:before {
      background: #242526;
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 100%;
      position: absolute;
      top: 130px !important;
      left: 36px !important;
    }

    .ps-timeline-sec .container {
        margin: 5px;
      }
    /* .ps-timeline-sec .container ol:after {
      background: #348e80;
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 100%;
      position: absolute;
      top: inherit !important;
      left: 36px;
    } */
    .ps-timeline-sec .container ol.ps-timeline {
      margin: 130px 0 !important;
      border-left: 2px solid #242526;
      padding-left: 0 !important;
      padding-top: 120px !important;
      border-top: 0 !important;
      margin-left: 25px !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li {
      height: 220px;
      float: none !important;
      width: inherit !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li:nth-child(2) .img-handler-bot img {
      width: 70px;
    }
    .ps-timeline-sec .container ol.ps-timeline li:last-child {
      margin: 0;
      bottom: 0 !important;
      height: 120px;
    }
    .ps-timeline-sec .container ol.ps-timeline li:last-child .img-handler-bot {
      bottom: 40px !important;
      width: 40% !important;
      margin-left: 25px !important;
      margin-top: 0 !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li:last-child .img-handler-bot img {
      width: 100%;
    }
    .ps-timeline-sec .container ol.ps-timeline li:last-child .ps-top {
      margin-bottom: 0 !important;
      top: 20px;
      width: 50% !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li span {
      left: 0 !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before {
      content: none !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:after {
      content: none !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
      content: none !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:after {
      content: none !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
      position: absolute !important;
      bottom: 150px !important;
      width: 30% !important;
      float: left !important;
      margin-left: 35px !important;
      margin-bottom: 0 !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
      margin: 0 auto !important;
      width: 80% !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
      position: absolute !important;
      bottom: 115px !important;
      width: 30% !important;
      float: left !important;
      margin-left: 35px !important;
      margin-bottom: 0 !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li p {
      text-align: left !important;
      width: 100% !important;
      margin: 0 auto !important;
      margin-top: 0px !important;
    }
    .ps-timeline-sec .container ol.ps-timeline li .ps-top {
      width: 60% !important;
      float: right !important;
      right: 0;
      top: -40px;
    }
    .ps-timeline-sec .container ol.ps-timeline li .ps-bot {
      width: 60% !important;
      float: right !important;
      right: 0;
      top: -40px;
    }
  }
  /* .ps-timeline-sec .container ol:before {
    background: #348e80;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    left: 8px;
    top: 49.5%;
  } */
  /* .ps-timeline-sec .container ol:after {
    background: #348e80;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    right: 8px;
    top: 49.5%;
  } */
  .ps-timeline-sec .container ol.ps-timeline {
    margin: 390px 0;
    padding: 0;
    border-top: 2px solid #242526;
    list-style: none;
  }
  .ps-timeline-sec .container ol.ps-timeline li {
    float: left;
    width: 25%;
    padding-top: 30px;
    position: relative;
  }
  .ps-timeline-sec .container ol.ps-timeline li span {
    width: 50px;
    height: 50px;
    margin-left: -25px;
    background: #fff;
    border: 3px solid #242526;
    border-radius: 50%;
    box-shadow: 0 0 0 0px #fff;
    text-align: center;
    line-height: 50px;
    color: #242526;
    font-size: 1.7em;
    font-style: normal;
    position: absolute;
    top: -26px;
    left: 50%;
  }
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:before {
    content: "";
    color: #242526;
    width: 2px;
    height: 50px;
    background: #242526;
    position: absolute;
    top: -50px;
    left: 50%;
  }
  /* .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-top:after {
    content: "";
    color: #242526;
    width: 8px;
    height: 8px;
    background: #242526;
    position: absolute;
    bottom: 90px;
    left: 44%;
    border-radius: 100%;
  } */
  .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:before {
    content: "";
    color: #242526;
    width: 2px;
    height: 50px;
    background: #242526;
    position: absolute;
    bottom: -50px;
    left: 50%;
  }
  /* .ps-timeline-sec .container ol.ps-timeline li span.ps-sp-bot:after {
    content: "";
    color: #242526;
    width: 8px;
    height: 8px;
    background: #242526;
    position: absolute;
    top: 90px;
    left: 44%;
    border-radius: 100%;
  } */
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-top {
    position: absolute;
    bottom: 0;
    margin-bottom: 130px;
    width: 100%;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-top img {
    display: table;
    margin: 0 auto;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot {
    position: absolute;
    margin-top: 60px;
    width: 100%;
  }
  .ps-timeline-sec .container ol.ps-timeline li .img-handler-bot img {
    display: table;
    margin: 0 auto;
  }
  .ps-timeline-sec .container ol.ps-timeline li p {
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-top {
    position: absolute;
    bottom: 0;
    margin-bottom: 100px;
  }
  .ps-timeline-sec .container ol.ps-timeline li .ps-bot {
    position: absolute;
    margin-top: 35px;
  }

  .ps-timeline-sec .container ol.ps-timeline li .ps-top p,   .ps-timeline-sec .container ol.ps-timeline li .ps-bot p{
    color: #242526;
    font-size: 22px;
    line-height: 1.3em;
    text-align: left;
  }

