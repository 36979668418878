div.upperFooter p.overFooterBold{
    text-align: center;
    font-size: 70px;
    line-height: 0;
    font-weight: 720;
    padding: 0;
    margin: 50px;
    /* line-height: 1em;*/
}

div.overFooterImg{
    position: relative;
    min-height: 60vh;
    width: 100%;
    background: url(../../images/landing04.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    object-fit: contain;
}

div.overFooterImg p{
    color: #fff;
    margin: 0;
}

div.upperFooter div.overFooterImg p:nth-child(2){
    margin-top: 30px;
}

div.upperFooter div.overFooterImg p:nth-child(3){
    margin-top: 20px;
    cursor: pointer;
}

div.upperFooter div.overFooterImg p.overFooterText2{
    font-size: 29px;
    line-height: 1.7em;
    font-weight: 500;
    padding-left: 10px;
}



div.upperFooter div.overFooterImg p.overFooterText2 i.contactBtnFooter{
    /* padding-right: 10px; */
    background: #fff;
    border-radius: 100%;
    padding: 13px 12px 9px 14px;
    color: #19181d;
    transition: 0.3s;
    margin-right: 5px;
}

div.upperFooter div.overFooterImg p.overFooterText2:hover .contactBtnFooter{
    color: #fff;
    background: #19181d;
    border: 1px solid #fff;
}

@media (max-width: 890px){
    div.upperFooter p.overFooterBold{
        font-size: 50px;
    }
}

@media (max-width: 665px){
    div.upperFooter p.overFooterBold{
        font-size: 40px;
    }
}

@media (max-width: 550px){
    div.upperFooter p.overFooterBold{
        font-size: 35px;
    }
}

@media (max-width: 495px){
    div.upperFooter p.overFooterBold{
        font-size: 30px;
    }
}

@media (max-width: 445px){
    div.upperFooter p.overFooterBold{
        font-size: 25px;
    }
}