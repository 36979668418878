div.aboutFirm div.aboutLawyerImgBackground{
    position: relative;
    min-height: 55vh;
    width: 100%;
    background: url(../../images/landing04.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: contain;
}

div.AboutContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    /* min-height: 100vh; */
    flex-direction: column;
    margin: 100px 0 0 0;
}

div.aboutWrapper{
    margin: 50px auto;
    width: 70%;
}

/* div.entry-Text h1{
    font-weight: 500;
    font-size: 42px;
} */


.secondary-Text p{
    text-align: justify;
    justify-content: center;
    margin: 0 auto;
    font-size: 19px;
    /* width: 70%; */
}

div.aboutWrapper img{
    max-width: 30vw;
    float: left;
    border-radius: 10px;
    margin-right: 15px;
}

/* div.aboutTextBox h2{
    font-weight: 500;
} */

div.aboutTextBox p{
    font-size: 19px;
    text-align: justify;
    text-indent: 20px;
}

div.workType{
    margin: 0;
}

div.workType h2{
    text-align: center;
    font-weight: 600;
    font-size: 35px;
    background: linear-gradient(90deg, rgba(0,117,75,1) 0%, rgba(0,181,116,1) 50%, rgba(0,117,75,1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

div.workTypeRow{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* flex-wrap: wrap; */
    margin: auto;
    padding: 30px;
    background: #f8f8f8;
    width: 90%;
}

/* div.workTypeRow p.outsideP::after{
    content: '+';
    position: relative;
    color: #b8863c;
    font-size: 25px;
} */

div.workTypeRow div.workTypeRowContent:nth-child(1){
    border: none;
}
div.workTypeRow div.workTypeRowContent{
    padding: 20px;
    border-left: 1px solid #abb8c3;
}

div.workTypeRow div.workTypeRowContent p{
    font-size: 18px;
    line-height: 1.7em;
}

div.workTypeRow div.workTypeRowContent p strong{
    font-size: 1.2em;
    display: block;
    font-weight: 700;
    color: #231f20;
}
/* RESPONSIVE 
div.aboutWrapper img{
    max-width: 70vw;
    float: none;
*/

@media screen and (max-width: 1010px){
    div.workTypeRow{
        flex-direction: column;
        padding: 30px 0;
        justify-content: center;
        align-items: flex-start;

    }
    
    div.workTypeRow div.workTypeRowContent:nth-child(1){
        border-bottom: 1px solid #abb8c3;
    }
    
    div.workTypeRow div.workTypeRowContent{
        border-bottom: 1px solid #abb8c3;
    }

    div.aboutWrapper img{
        max-width: 80vw;
        float: none;
    }

    div.aboutWrapper{
        /* margin: 50px auto; */
        width: 80%;
    }
    .secondary-Text p{
        width: 80%;
    }
}
