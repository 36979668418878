div.blogPageDiv div.blogPageImgBacground{
    position: relative;
    min-height: 55vh;
    width: 100%;
    background: url(../../images/landing04.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: contain;
}

div.blogPageDiv div.bodyDivBlog{ 
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #eee; */
    /* padding: 0 1.5rem; */
    margin: 50px auto; 
}

div.blogPageDiv h1{
    letter-spacing: 1px;
    font-size: 52px;
    font-weight: 500;
    padding: 10px;
    margin-top: 90px;
    text-align: center;
}

.blogContainer {
    width: 100%;

}

 /* .blogContainer h2 {
    letter-spacing: 1px;
    font-size: 50px;
    color: #6968aa;
    border: 2px dashed #0181a0;
    padding: 10px;
    text-transform: uppercase;
    border-radius: 10px;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    margin-top: 90px;
}  */

.blog-post {
    width: 70%;
    max-width: 98rem;
    padding: 5rem;
    background-color: #dbf4ff21;
    box-shadow: 0 1.4rem 8rem rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    border-radius: 20px;
    margin: 20px auto;
}

.blog-post_img {
    min-width: 35rem;
    max-width: 35rem;
    height: 30rem;
    transform: translateX(-8rem);
    position: relative;
}

.blog-post_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: .8rem;
    display: block;
}

.blog-post_img img::before {
    content: '';
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    box-shadow: .5rem .5rem 3rem 1px rgba(0, 0, 0, 0.5);
    border-radius: .8rem;
}

.blog-post_date span {
    display: block;
    color: #00000080;
    font-size: 1.6rem;
    font-weight: 600;
    margin: .5rem 0;
}

.blog-post_title {
    font-size: 2.5rem;
    margin: 1.5rem 0 2rem;
    text-transform: uppercase;
    
    background: linear-gradient(90deg, rgba(0,117,75,1) 0%, rgb(0, 163, 103) 50%, rgba(0,117,75,1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.blog-post_text {
    margin-bottom: 3rem;
    font-size: 1.4rem;
    color: #000000b3;
}

.blog-post_cta {
    display: inline-block;
    padding: 1.5rem 3rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: #fff;
    text-decoration: none;
    border-radius: .8rem;
    background-size: 200%;
    background-image: linear-gradient(to left, rgb(0, 133, 84), rgb(0, 85, 54) ,  rgb(0, 133, 84));
    transition: 0.6s;
}

.blog-post_cta:hover {
    background-position: right;
    /* background-image: linear-gradient(to right, rgb(0, 85, 54), rgb(0, 133, 84), rgb(0, 85, 54)); */
}

@media screen and (max-width: 1068px) {
    .blog-post {
        max-width: 80rem;
    }
    .blog-post_img {
        min-width: 30rem;
        max-width: 30rem;
    }
    .blogContainer h2 {
        margin-top: 120px;
        margin-left: 275px;
    }
    /* div.blogPageDiv div.bodyDivBlog{
        margin: 40px 10px;
    } */
}

@media screen and (max-width: 868px) {
    .blog-post {
        max-width: 70rem;
    }
    .blogContainer h2 {
        margin-top: 20px;
        margin-left: 142px;
    }
}

@media screen and (max-width: 768px) {
    .blog-post {
        padding: 2.5rem;
        flex-direction: column;
    }
    .blog-post_img {
        min-width: 100%;
        max-width: 100%;
        transform: translate(0, -1rem);
    }
    .blogContainer {
        margin-top: auto;
    }
}

@media screen and (max-width: 823px) {
    .blogContainer h2 {
        margin-top: 35px;
        margin-left: 142px;
    }
}
        