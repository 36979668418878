/* div.contactPage{
    min-height: 100vh;
} */

div.contactPage div.contactImgBackground{
    position: relative;
    min-height: 55vh;
    width: 100%;
    background: url(../../images/landing12.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    object-fit: contain;
}

section.contact{
    position: relative;
    min-height: 80vh;
    padding: 50px 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

section.contact .contactContent{
    max-width: 800px;
    text-align: center;
}

section.contact .contactContent h1{
    font-size: 42px;
    font-weight: 500;
    color: #000;
}

section.contact .contactContent p{
    font-weight: 300;
    color: #000;
}

div.contactContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

div.contactContainer .contactInfo{
    width: 50%;
    display: flex;
    flex-direction: column;
}

div.contactContainer .contactInfo .leftBox{
    position: relative;
    padding: 20px 0;
    display: flex;
}

div.contactContainer .contactInfo .leftBox .iconBoxDiv{
    min-width: 60px;
    height: 60px;
    /* background: #000;
    color: #fff; */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 22px;
}

div.contactContainer .contactInfo .leftBox .textBoxDiv{
    display: flex;
    margin-left: 20px;
    font-size: 16px;
    color: #000;
    flex-direction: column;
    font-weight: 300;
}

div.contactContainer .contactInfo .leftBox .textBoxDiv h3{
    font-weight: 500;
    color: #00754B;
}

div.contactForm{
    width: 50%;
    padding: 40px 200px;
    background: #fff;
    box-shadow: .3rem .3rem 2rem 0 rgba(0, 0, 0, 0.3);
    border-radius: 30px;
}

div.contactForm h2{
    font-weight: 500;
    color: #333;
    font-size: 30px;
}

div.contactForm .inputBox{
    position: relative;
    width: 100%;
    margin-top: 30px;
}

div.contactForm .checkBoxInput{
    cursor: pointer;
    margin-top: 30px;
    font-size: 19px;
}

div.contactForm .checkBoxInput label, div.contactForm .checkBoxInput input{
    cursor: pointer;
}

div.contactForm .inputBox input,
div.contactForm .inputBox textarea{
    width: 100%;
    padding: 5px 0;
    font-size: 16px;
    margin: 10px 0;
    border: none;
    border-bottom: 1px solid #333;
    outline: none;
    resize: none;
}

div.contactForm .inputBox span{
    position: absolute;
    left: 0;
    padding: 5px 0;
    font-size: 16px;
    margin: 10px 0;
    pointer-events: none;
    transition: 0.5s;
    color: #666;
}

div.contactForm .inputBox input:focus ~ span,
div.contactForm .inputBox input:valid ~ span,
div.contactForm .inputBox textarea:focus ~ span,
div.contactForm .inputBox textarea:valid ~ span{
    color: #e91e63;
    font-size: 12px;
    transform: translateY(-20px);
}

div.contactForm .inputBox input[type="submit"]{
    /* width: 100px; */
    background: #00754B;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 15px 0;
    font-size: 18px;
    transition: 0.3s;
    border-radius: 5px;
}

div.contactForm .inputBox input[type="submit"]:hover{
    background: #008856;
}

.mapIframe{
    width: 100%;
    height: 60vh;
    margin: 0;
    padding: 0;
}
/* @media screen and (max-width: 991px) { */
@media (max-width: 1010px) {
    section.contact{
        padding: 50px;
        overflow: hidden;
    }
    div.contactContainer{
        flex-direction: column;
    }
    div.contactContainer .contactInfo{
        margin-bottom: 40px;
        width: 100%;
    }
    div.contactContainer .contactInfo,
    div.contactForm{
        width: 100%;
    }
}