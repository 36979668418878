div.oferFooterBasic{
    min-height: 40vh;
    background-color: #19181d;
    margin-top: 15vh;

}

div.oferFooterBasic div.basicTextFooter{
    display: flex;
    width: 50vw;
    justify-content: center;
    align-items: center; 
    /* padding: 50px; */
    flex-direction: column;
    background: #fff;
    padding: 100px 0;
    border-radius: 50px;
    margin: auto;

    position: relative;
    top: -100px;
    /* left: 25vw; */

    box-shadow: .5rem .5rem 3rem 2px rgba(0, 0, 0, 0.7);
}

div.oferFooterBasic div.basicTextFooter p strong{
    display: block;
    text-align: center;
    line-height: 1.5em;
    font-size: 42px;
}

p.basicFooterContact{
    font-size: 24px;
    cursor: pointer;
}

p.basicFooterContact .contactBtnFooter{
    background: #19181d;
    border-radius: 100%;
    padding: 11px 10px 7px 12px;
    color: #fff;
    transition: 0.3s;
    margin-right: 5px;
}

p.basicFooterContact:hover .contactBtnFooter{
    color: #000;
    background: #fff;
    border: 1px solid #19181d;
}

@media (max-width: 1080px){

    div.oferFooterBasic div.basicTextFooter{
        /* max-width: 100vh; */
        min-width: 52vh;
        /* left: 10vh; */
        padding: 40px 0;
    }

    div.oferFooterBasic div.basicTextFooter p strong{
        font-size: 27px;
    }

    p.basicFooterContact{
        font-size: 21px;
    }
}

/* @media (max-width: 650px){
    div.oferFooterBasic div.basicTextFooter{
        max-width: 52vh;
        padding: 40px 0;
    }
} */