.progress {
    position: fixed;
    z-index: 999;
    bottom: 40px;
    right: 30px;
    height: 70px;
    width: 70px;
    display: none;
    place-items: center;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  .progress-value {
    display: block;
    height: calc(100% - 15px);
    width: calc(100% - 15px);
    background-color: #ffffff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    font-size: 35px;
    color: #001a2e;
  }

  div.progress.activate{
    display: grid;
  }