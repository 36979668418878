 html{
  scroll-behavior: smooth;
  overflow-x: hidden;
 }

 .App {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.MainTitles{
  font-size: 52px;
  font-weight: 500;
}
