div.sideMenu{
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    width: 400px;
    min-height: 100vh;
    /* margin: auto 0; */
    background: #fff;
    z-index: 5;
    /* box-shadow: .01rem 0 1rem 0 rgba(0, 0, 0, 0.7); */
}

div.sideMenu a{
    padding: 19px;
    text-decoration: none;
    color: #666;
    border-bottom: 0.5px solid #666;
    transition: 0.4s;
}

div.sideMenu a.active{
    color: #00754B;
}

div.sideMenu a:hover{
    color: #00754B;
}


@media screen and (max-width: 1265px) {
    div.sideMenu{
        display: none;
    }
  }